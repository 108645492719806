import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CoderpadEditor from 'components/coderpad/CoderpadEditor';
import CoderpadResults from 'components/coderpad/CoderpadResults';
import configuration from 'constants/coderpad/configuration';
import templates from 'constants/coderpad/templates';
import languages from 'constants/coderpad/languages';
import messages from 'constants/coderpad/messages';
import { Grid, Button, FormControl, FormControlLabel, Checkbox, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { StyleSheet, css } from 'aphrodite';

// const EXECUTE_CODE_ENDPOINT = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_CODERPAD_PROD : process.env.REACT_APP_CODERPAD_DEV;
// const LOAD_CODE_ENDPOINT = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PASTEBIN_LOAD_PROD : process.env.REACT_APP_PASTEBIN_LOAD_DEV;
// const SAVE_CODE_ENDPOINT = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PASTEBIN_SAVE_PROD : process.env.REACT_APP_PASTEBIN_SAVE_DEV;

const EXECUTE_CODE_ENDPOINT = process.env.REACT_APP_CODERPAD_PROD;
const LOAD_CODE_ENDPOINT = process.env.REACT_APP_PASTEBIN_LOAD_PROD;
const SAVE_CODE_ENDPOINT = process.env.REACT_APP_PASTEBIN_SAVE_PROD;

function CoderpadWrapper(props) {
  const [visibleAnnouncement, setVisibleAnnouncement] = useState(true);
  const [isExecuting, setIsExecuting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [practiceMode, setPracticeMode] = useState(configuration['PRACTICE_MODE']);
  const [sourcePython, setSourcePython] = useState(templates['python']);
  const [sourceJava, setSourceJava] = useState(templates['java']);
  const [sourceCpp, setSourceCpp] = useState(templates['c_cpp']);
  const [sourceLanguage, setSourceLanguage] = useState(configuration['DEFAULT_LANGUAGE']);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (props.match.path === '/coderpad/:id') {
      const pasteId = props.match.params.id;
      const getUrl = LOAD_CODE_ENDPOINT.replace('%s', pasteId);
  
      axios.get(getUrl)
        .then(response => {
          const status = response.data.status
          if (status === 'success') {
            const language = response.data.type;
            const code = response.data.text;

            if(['java', 'python', 'c_cpp'].includes(language)) {
              setSourceLanguage(language);
              if (language === 'python') setSourcePython(code);
              if (language === 'java') setSourceJava(code);
              if (language === 'c_cpp') setSourceCpp(code);
            }
          }
        }).catch(error => {
          return;
        })
    }
  })

  const editCurrentCode = (value) => {
    if (sourceLanguage === 'python') setSourcePython(value);
    else if (sourceLanguage === 'java') setSourceJava(value);
    else if (sourceLanguage === 'c_cpp') setSourceCpp(value);
  };

  const getCurrentCode = () => {
    if (sourceLanguage === 'python') return sourcePython;
    else if (sourceLanguage === 'java') return sourceJava;
    else if (sourceLanguage === 'c_cpp') return sourceCpp;
  };

  const addExecutingMessage = () => {
    setLogs([messages['EXECUTING_CODE']].concat(logs));
  };

  const addToLogs = (result) => {
    let resultOutput = null;
    if (result['build'] === true) {
      resultOutput = '[' + languages[sourceLanguage] + '] Build successfully completed!\nStandard output:\n' + result['message'];
    } else {
      resultOutput = '[' + languages[sourceLanguage] + '] Build failed!\nBuild errors:\n' + result['message'];
    }

    if (resultOutput) {
      setLogs([resultOutput].concat(logs));
    }
  };

  const addErrorToLogs = () => {
    setLogs([messages['EXECUTION_ERROR']].concat(logs));
  };

  const getLogs = () => {
    return logs.join('\n\n');
  }

  const executeCode = () => {
    setIsExecuting(true);
    addExecutingMessage();

    axios.post(EXECUTE_CODE_ENDPOINT, {
      language: sourceLanguage,
      code: getCurrentCode(),
    }).then(response => {
      const build = response['data']['build'];
      const run = response['data']['run'];
      const result = { build: null, message: null };

      if (run !== 'None') {
        result['build'] = true;
        result['message'] = run;
      } else {
        result['build'] = false;
        result['message'] = build;
      }

      setIsExecuting(false);
      addToLogs(result);
    }).catch(error => {
      addErrorToLogs();
      setIsExecuting(false);
    })
  };

  return (
    <div>
      <Dialog
        open={visibleAnnouncement}
        onClose={() => setVisibleAnnouncement(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Services are currently down.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            I am currently in the process of migrating all of my projects into one core application.
            Until the migration is complete, all AWS services have been suspended temporarily.
            Sorry for the inconvenience.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisibleAnnouncement(false)} color='primary' autoFocus>
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid item xs={6}>
          <CoderpadEditor
            language={practiceMode ? 'plain_text' : sourceLanguage}
            onChange={editCurrentCode}
            code={getCurrentCode()}
          />
        </Grid>
        <Grid item xs={6}>
          <CoderpadResults
            logs={getLogs()}
          />
        </Grid>
      </Grid>
      <Grid container className={css(styles.menu)}>
        <Grid item xs={6}>
          <FormControl variant='outlined' className={css(styles.form)}>
            <Select
              labelId='select-language-label'
              id='select-language'
              value={sourceLanguage}
              onChange={(event) => setSourceLanguage(event.target.value)}
              style={{ color: '#ffffff' }}
            >
              <MenuItem value='java'>{languages['java']}</MenuItem>
              <MenuItem value='python'>{languages['python']}</MenuItem>
              <MenuItem value='c_cpp'>{languages['c_cpp']}</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
              control={
                <Checkbox
                  checked={practiceMode}
                  onChange={() => setPracticeMode(!practiceMode)}
                  value='practice'
                  className={css(styles.checkbox)}
                />
              }
              label='Practice Mode'
              className={css(styles.practiceForm)}
          />
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button variant='contained' color='primary' 
            disabled={true}
            className={css(styles.button)}
          >
            {isUploading ? 'Uploading your code...' : 'Save Code' }
          </Button>
          <Button variant='contained' color='primary' 
            onClick={() => setLogs([])}
            className={css(styles.button)}
          >
            Clear Logs
          </Button>
          <Button variant='contained' color='primary'
            onClick={executeCode}
            disabled={isExecuting}
            className={css(styles.submitButton)}
          >
            {isExecuting ? messages['EXECUTING_CODE'] : 'Run Code'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const styles = StyleSheet.create({
  menu: {
    paddingTop: '1.5%',
    height: '12vh',
    backgroundColor: '#2B2828',
    color: '#ffffff',
  },
  form: {
    textAlign: 'center',
    width: '20%',
    marginLeft: '1.5%',
    marginRight: '2.5%',
  },
  checkbox: {
    color: '#0269a4',
  },
  practiceForm: {
    marginTop: '1%',
  },
  button: {
    marginTop: '1%',
    marginRight: '2.5%',
    background: '#0269a4',
    maxHeight: '50px',
  },
  submitButton: {
    marginTop: '1%',
    marginRight: '2.5%',
    background: '#2D804F',
    maxHeight: '50px',
  },
});

export default CoderpadWrapper;
