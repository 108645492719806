import React from 'react';

function PastebinWrapper(props) {
  return (
    <header className="App-header">
      <p>
        {/* // eslint-disable-next-line */}
        🚧 Pastebin client is currently under constuction. 🚧
      </p>
    </header>
  );
}

export default PastebinWrapper;
