import React from 'react';
import routes from 'routes/routes';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import NavigationBar from 'components/navigation/NavigationBar';
import Home from 'components/home/Home';
import CoderpadWrapper from 'components/coderpad/CoderpadWrapper';
import PastebinWrapper from 'components/pastebin/PastebinWrapper';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <NavigationBar />
        <Route
          exact
          path={routes['default']}
          render={Home}
        />
        <Route 
          exact
          path={routes['coderpad']} 
          render={(props) => <CoderpadWrapper {...props} />}
        />
        <Route
          exact
          path={routes['coderpadId']}
          render={(props) => <CoderpadWrapper {...props} />}
        />
        <Route 
          exact
          path={routes['pastebin']} 
          render={(props) => <PastebinWrapper {...props} />}
        />
        <Route
          exact
          path={routes['pastebinId']}
          render={(props) => <PastebinWrapper {...props} />}
        />
      </Router>
    </div>
  );
}

export default App;
