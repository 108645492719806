import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  projectName: {
    marginTop: "-1.5%",
    color: "#61dafb",
  },
});

function Home() {
  return (
    <header className="App-header">
      <p>
        {/* // eslint-disable-next-line */}
        Core is currently under construction. 🚧
      </p>

      <p>
        Applications migrated so far:
      </p>
      {/* eslint-disable-next-line  */}
      <p className={css(styles.projectName)}>
        ✅ Coderpad
      </p>

      <p>
        Applications to be migrated:
      </p>
      {/* eslint-disable-next-line  */}
      <p className={css(styles.projectName)}>
        ❌ Pastebin
      </p>
      {/* eslint-disable-next-line  */}
      <p className={css(styles.projectName)}>
        ❌ Grade Calculator
      </p>
    </header>
  );
}

export default Home;
